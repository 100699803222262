$accent-negative-color: #f95721;
$text-negative-color: #d8400c;
$mts-compact: 'MTS Compact';

@mixin mts-compact-normal {
  font-family: $mts-compact;
  font-style: normal;
}

.custom-input {
  display: flex;
  flex-direction: column;

  &__textfield-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    background: var(--background-light-secondary, #f2f3f7);
    border: 1px solid rgba(188, 195, 208, 0.5);
    border-radius: 6px;
    height: 42px;
  }

  &__required {
    font-weight: bold;
    color: $text-negative-color;
  }

  &__invalid {
    color: $text-negative-color;
    input {
      border: 1px solid $accent-negative-color;
      &:focus {
        outline: none;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &__form-field {
    &-label {
      color: var(--text-light-secondary, #626c77);
      @include mts-compact-normal;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 5px;
      margin-top: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      &__required,
      &__invalid {
        color: $text-negative-color;
      }
    }

    &-icon {
      fill: var(--icon-light-secondary, #8d969f);
    }
  }

  &__form-field-subLabel {
    margin-top: 4px;
    color: var(--text-light-secondary, #626c77);
    @include mts-compact-normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  &__textfield {
    border: none;
    width: 100%;
    padding: 10px 12px;
    border-radius: 6px;
    background: var(--background-light-secondary, #f2f3f7);
    transition: outline 0z.5s ease;
    outline: 1px solid transparent;
    @include mts-compact-normal;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;

    &:focus {
      outline: 1px solid var(--accent-light-active, #007cff);
      border: none;
    }

    &:hover:not(:focus) {
      outline: 1px solid #000;
      border: none;
    }

    &__invalid {
      background-size: 24px;
      background: var(--background-light-secondary, #f2f3f7);
      outline: 1px solid $accent-negative-color;
    }

    input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
    }
  }

  &__errors {
    color: $text-negative-color;
    @include mts-compact-normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
  }

  &__textfieldIcon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    cursor: pointer;
  }
}
