.footer {
  background-color: #f4f4f5;

  @supports (-moz-appearance: none) {
    height: 40vh;
  }

  &__zoom-container {
    padding: 4rem 4.5rem;
  }

  &__product-info {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    font-family: 'MTS Compact';
    max-width: 35%;

    & > img {
      width: 3.5rem;
      pointer-events: none;
    }

    & > p {
      margin: 0;
    }
  }

  &__product-info-heading {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 500;
  }

  &__product-info-text {
    font-size: 14px;
    line-height: 1.5;
    color: var(--color-text-primary);
  }

  @media screen and (max-width: 1280px) {
    &__zoom-container {
      padding: 3rem 4.5rem;
    }

    &__product-info-text {
      font-size: 0.75rem;
    }

    &__links {
      font-size: 0.75rem;
      row-gap: 0.75rem;
    }
  }

  @media screen and (max-width: 960px) {
    &__zoom-container {
      padding: 4rem 3rem;
    }

    &__product-info {
      max-width: unset;
    }
  }

  @media screen and (max-width: 640px) {
    &__zoom-container {
      padding: 4rem 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__zoom-container {
      padding: 4rem 0.75rem;
      flex-direction: column;
      row-gap: 1rem;
    }

    &__product-info {
      max-width: 100%;
    }
  }
}
