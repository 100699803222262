.modal-card-overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: var(--color-background-overlay);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-card {
  width: 440px;
  background-color: var(--color-background-primary);
  border-radius: 1.25rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.25rem;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }

  &__icon {
    width: 2.75rem;
    height: 2.75rem;
  }

  &__heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
  }

  &__title {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  &__subtitle {
    margin: 0;
    font-size: 14px;
    line-height: 1.25rem;
  }

  &__body {
    width: 100%;
  }
}
