.advantages {
  scroll-margin-top: 180px;

  @supports (-moz-appearance: none) {
    height: calc(100vh - 17.5rem);
  }

  &__zoom-container {
    padding: 0 4.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
  }

  &__cards-container {
    display: flex;
    justify-content: space-between;

    &:has(> .advantages__card:hover) {
      & > .advantages__card:not(:hover) {
        width: 9.25rem;
        border-radius: 0px;
      }
    }
  }

  &__card {
    width: 10.75rem;
    height: 15.5rem;
    background-size: cover;
    background-position: center;
    border-radius: 14px;
    padding: 0.75rem 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    color: var(--color-text-inverted);
    transition: all 0.5s linear;
    overflow: hidden;
    cursor: default;

    &--t2m {
      background-image: url('../../../../public/assets/images/advantages_t2m.png');
    }

    &--team {
      background-image: url('../../../../public/assets/images/advantages_team.png');
    }

    &--integration {
      background-image: url('../../../../public/assets/images/advantages_integration.png');
    }

    &--development {
      background-image: url('../../../../public/assets/images/advantages_development.png');
    }

    &--experience {
      background-image: url('../../../../public/assets/images/advantages_experience.png');
    }

    &:hover {
      width: 17.25rem;

      & .advantages__card-text {
        opacity: 1;
      }
    }

    & > p {
      margin: 0;
    }
  }

  &__card-heading {
    font-family: 'MTS Wide';
    font-size: 1.25rem;
    line-height: 1.5;
  }

  &__card-text {
    opacity: 0;
    font-size: 14px;
    line-height: 1.35;
    width: 17.25rem;
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 1280px) {
    &__cards-container {
      overflow-x: auto;
      max-width: 100%;
      column-gap: 0.5rem;
      padding-bottom: 0.5rem;

      &:has(> .advantages__card:hover) {
        & > .advantages__card:not(:hover) {
          width: inherit;
          border-radius: 14px;
        }
      }

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #cbcbcb;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2b2b2b;
        border-radius: 5px;
      }
    }

    &__card {
      width: 100%;
      overflow: visible;
    }

    &__card-text {
      opacity: 1;
    }
  }

  @media screen and (max-width: 960px) {
    &__zoom-container {
      padding: 0 3rem;
    }
  }

  @media screen and (max-width: 640px) {
    &__zoom-container {
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__zoom-container {
      padding: 0 0.75rem;
    }

    &__card {
      max-width: calc(100% - 2rem);
    }
  }
}
