.header {
  width: 100vw;
  height: auto;
  position: fixed;
  top: 0;
  z-index: 10;
  transition: background-color 0.3s linear;
  transform-origin: top left;

  &__zoom-container {
    display: flex;
    padding: 0 4.5rem;
    justify-content: space-between;
    align-items: center;
  }

  &__navigation-wrapper {
    display: flex;
    height: 3.375rem;
  }

  &__logo {
    width: auto;
    height: 3.375rem;
    pointer-events: none;
  }

  &__navigation {
    display: flex;
    align-items: center;
    font-family: 'MTS Compact';
    font-size: 14px;
    line-height: 1.25rem;
    font-weight: 500;
  }

  &__navigation-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    column-gap: 2rem;
    height: 100%;
  }

  &__navigation-list-item {
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    &--active {
      box-shadow: 0 -2px var(--color-brand) inset;
    }
  }

  &__navigation-link {
    color: inherit;
    text-decoration: none;
  }

  & > button {
    font-family: 'MTS Wide';
    font-size: 14px;
    font-weight: 700;
    line-height: 1.25rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border-radius: 0.75rem;
  }

  &--scrolled {
    background-color: var(--color-background-primary);
  }
}

.burger-menu-header {
  display: none;
  position: absolute;
  z-index: 1000;
  width: calc(100% - 1.25rem);
  justify-content: space-between;
  padding-left: 1.25rem;

  & > button {
    position: fixed;
    right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .header {
    display: none;

    &--opened {
      display: flex;
      align-items: flex-start;
      // используем important, чтобы перебить инлайновые стили для FF
      height: calc(100vh - 4.25rem) !important;
      flex-direction: column;
      width: 250px;
      padding: 1.5rem;
      padding-top: 2.75rem;
    }

    &__zoom-container {
      padding: 0;
      align-items: start;
    }

    &__navigation-wrapper {
      flex-direction: column;
      row-gap: 1.5rem;
      align-items: flex-start;
    }

    &__navigation-list {
      flex-direction: column;
      row-gap: 1rem;
    }
  }

  .burger-menu-header {
    display: flex;
  }

  .body-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    background-color: var(--color-background-overlay);
  }
}
