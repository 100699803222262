.about {
  scroll-margin-top: 160px;

  @supports (-moz-appearance: none) {
    height: calc(100vh - 12.5rem);
  }

  &__zoom-container {
    padding: 0 4.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
  }

  &__accordion-wrapper {
    display: flex;
    column-gap: 2rem;
  }

  &__accordion-fields {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  &__accordion-field {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    color: #393939;
    flex-basis: 2.5rem;
    overflow: hidden;
    transition: flex 0.3s linear;

    &--opened {
      flex: 1;

      .about__accordion-field-button {
        rotate: 45deg;
      }
    }

    &:hover {
      .about__accordion-field-button {
        background-color: #eee;
      }
    }

    & p {
      margin: 0;
    }

    & > .about__accordion-content-wrapper {
      display: none;
    }
  }

  &__accordion-field-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'MTS Wide';
    font-size: 1.125rem;
    line-height: 1.25;
    cursor: pointer;
  }

  &__accordion-field-button {
    background-color: transparent;
    background-image: url('../../../../public/assets/icons/cross.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    transition: all 0.3s linear;
    cursor: pointer;
  }

  &__accordion-field-content {
    font-size: 14px;
    line-height: 1.6;
  }

  &__accordion-content-wrapper {
    flex: 0.6;
    background-image: url('../../../../public/assets/images/about_frame.svg');
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0.5rem 1.5rem 0px #0000001f;
    border-radius: 1.25rem;
    padding: 2rem 0.5rem 0.5rem;
    height: fit-content;
    transition: all 0.3s ease;
  }

  &__accordion-content-picture {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s ease;
    aspect-ratio: 193/96;
    pointer-events: none;
  }

  @media screen and (max-width: 960px) {
    @supports (-moz-appearance: none) {
      max-height: calc(100vh - 17.5rem);
    }

    &__zoom-container {
      padding: 0 3rem;
    }

    &__accordion-wrapper > &__accordion-content-wrapper {
      display: none;
    }

    &__accordion-content-wrapper {
      box-shadow: none;
    }

    &__accordion-fields {
      flex: 1;
    }

    &__accordion-field > &__accordion-content-wrapper {
      display: block;
    }
  }

  @media screen and (max-width: 640px) {
    &__zoom-container {
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__zoom-container {
      padding: 0 0.75rem;
    }

    &__accordion-field-title {
      font-size: 1rem;
    }
  }
}
