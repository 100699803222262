.digits {
  height: calc(100vh - 40px);
  scroll-margin-top: 80px;

  & .section-heading {
    padding: 0 4.5rem;
  }

  &__zoom-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  &__horizontal-scroll-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    column-gap: 7.5rem;
    position: absolute;
    padding: 9rem 4.5rem 2.5rem;
  }

  &__content-item {
    position: relative;
  }

  &__content-heading {
    font-family: 'MTS Wide';
    color: var(--color-brand);
    font-size: 9.375rem;
    font-weight: 400;
    letter-spacing: -4px;
    line-height: 1.1;
    margin: 0;
  }

  &__content-text {
    font-size: 14px;
    line-height: 1.3;
    margin: 0;
    margin-left: 14px;
    margin-top: -1.5rem;
    width: 50%;
  }

  &__content-image {
    position: absolute;
    height: 15rem;
    z-index: -1;
    pointer-events: none;
  }

  @media screen and (max-width: 960px) {
    &__zoom-container {
      padding: 0 3rem;
    }

    & .section-heading {
      padding: 0;
    }

    &__horizontal-scroll-wrapper {
      overflow: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #cbcbcb;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2b2b2b;
        border-radius: 5px;
      }
    }

    &__content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (max-width: 640px) {
    &__zoom-container {
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__zoom-container {
      padding: 0 0.75rem;
    }

    &__content {
      column-gap: 2rem;
    }

    &__content-heading {
      font-size: 6rem;
    }

    &__content-text {
      width: 100%;
      margin: 0;
    }

    // используем important, так как изначально позиция задаётся в style для каждого изображения
    &__content-image {
      height: 12rem;
      top: -8.75rem !important;
      right: 0 !important;
    }
  }
}
