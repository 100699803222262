.feedback {
  height: calc(100vh - 10.75rem);

  &__zoom-container {
    padding: 0 4.5rem;
    height: 100%;
  }

  &__container {
    height: 100%;
    background: radial-gradient(
      115.66% 201.79% at -30.14% 158.77%,
      #7a8ffc 3.52%,
      #8bb0fd 37.66%,
      #97b6ef 58.11%,
      #efd2e1 96.4%
    );
    border-radius: 1.75rem;
    padding-right: 6rem;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  & .section-heading {
    color: #ffffff;
    line-height: 1.875rem;
  }

  & img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 45%;
    z-index: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    justify-content: center;
    z-index: 1;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    row-gap: 2.25rem;

    & > button {
      font-size: 0.625rem;
      line-height: 1.25rem;
      letter-spacing: 0.5px;
      font-family: 'MTS Wide';
      font-weight: 700;
      padding: 0.75rem 0;
      text-transform: uppercase;
      flex: 1;
      width: 250px;
    }
  }

  &__info-block-text {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    & > p {
      font-family: 'MTS Wide';
      margin: 0;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: #fff;
    }
  }

  @media screen and (max-width: 960px) {
    &__zoom-container {
      padding: 0 3rem;
    }

    &__container {
      padding: 1rem 0;
      padding-right: 1rem;
      height: calc(100% - 2rem);
    }

    & img {
      height: auto;
      bottom: 0;
      top: unset;
      transform: none;
    }

    &__content {
      row-gap: unset;
      justify-content: space-between;
    }

    &__info-block {
      row-gap: 1.5rem;

      & > button {
        width: 90%;
      }
    }

    &__info-block-text {
      row-gap: 1rem;

      & > p {
        font-size: 0.75rem;
      }
    }

    .section-heading {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 640px) {
    &__zoom-container {
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__zoom-container {
      padding: 0 0.75rem;
    }

    & img {
      display: none;
    }

    &__container {
      padding-right: 0;
      justify-content: center;
    }
  }
}
