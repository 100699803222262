.users {
  max-height: calc(100vh - 17.5rem);
  scroll-margin-top: 80px;

  @supports (-moz-appearance: none) {
    height: calc(100vh - 17.5rem);
  }

  &__zoom-container {
    padding: 0 4.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
  }

  &__container {
    display: flex;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;
  }

  &__item {
    flex: 1;
    height: 5.75rem;
    background-color: #f5f5f5;
    border-radius: 14px;
    padding: 1.5rem 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
      & > .users__item-image {
        opacity: 0;
      }

      & > .users__item-text {
        opacity: 1;
      }
    }
  }

  &__item-image {
    scale: 0.8;
    transition: opacity 0.3s linear;
    pointer-events: none;
  }

  &__item-text {
    opacity: 0;
    margin: 0;
    position: absolute;
    top: 1.5rem;
    left: 1.75rem;
    max-width: calc(100% - 3.5rem);
    max-height: calc(100% - 3rem);
    overflow: auto;
    transition: opacity 0.3s linear;
  }

  @media screen and (max-width: 960px) {
    max-height: unset;

    &__zoom-container {
      padding: 0 3rem;
    }

    &__item {
      width: calc((100% - 1rem) / 2 - 3.5rem);
      flex: auto;
    }
  }

  @media screen and (max-width: 640px) {
    &__zoom-container {
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__zoom-container {
      padding: 0 0.75rem;
    }

    &__item {
      padding: 1rem;
      width: calc((100% - 1rem) / 2 - 2rem);
    }

    &__item-image {
      max-width: 100%;
      scale: 1;
    }

    &__item-text {
      max-width: calc(100% - 2rem);
      max-height: calc(100% - 2rem);
      top: 1rem;
      left: 1rem;
      font-size: 0.75rem;
    }
  }
}
