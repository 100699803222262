.beta-modal-body {
  max-height: calc(100vh - 12.5rem - 2.5rem);
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  &__actions {
    padding-bottom: 1.25rem;
    position: sticky;
    bottom: 0;
    padding-top: 0.75rem;
    background-color: var(--color-background-primary);
    margin-top: -0.75rem;
  }
}

.autocomplete-option-title {
  font-size: 14px;
  line-height: 1.25rem;
}

.autocomplete-option-info {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--color-text-secondary);
  display: flex;
  column-gap: 0.25rem;

  &__address {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.devider {
  height: auto;
  width: 1px;
  background-color: var(--color-background-stroke);
}
