.solutions {
  height: calc(100vh - 2rem);
  scroll-margin-top: 80px;

  &__zoom-container {
    padding: 0 4.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    height: 100%;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    height: 100%;
  }

  &__tabs-list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    column-gap: 2.5rem;
    width: fit-content;
    font-family: 'MTS Wide';
    font-size: 1rem;
    line-height: 1.5;
    border-bottom: 1px solid var(--color-constant-greyscale-200);
  }

  &__tabs-list-item {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;
    position: relative;
    padding: 0 0.625rem 1rem;
    flex: 1;

    &--active {
      &::after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: var(--color-brand);
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__tabs-icon {
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__tabs-content-wrapper {
    background-color: #f5f5f5;
    border-radius: 1.5rem;
    flex: 1;
    padding: 3.75rem 3.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
  }

  &__tabs-content-item {
    background-color: var(--color-background-primary);
    border-radius: 1rem;
    padding: 1.75rem;
    box-shadow: 0px 6.740920543670654px 25.840194702148438px 0px #0000001f;
    font-family: 'MTS Text';
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    position: relative;
  }

  &__tabs-content-heading {
    margin: 0;
    font-size: 1rem;
    line-height: 1.1;
    font-weight: 500;
  }

  &__tabs-content-text {
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
    padding-left: 1rem;
  }

  &__soon-badge {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.75rem 1.25rem;
    border-radius: 1rem;
    border-bottom-right-radius: 0;
    font-size: 14px;
    line-height: 1;
    background-color: #393939;
    color: var(--color-text-inverted);
    font-family: 'MTS Wide';
  }

  @media screen and (max-width: 1280px) {
    height: auto;
    max-height: calc(100vh - 2rem);

    &__tabs-content-wrapper {
      padding: 1.25rem;
      gap: 1rem;
    }

    &__soon-badge {
      padding: 0.375rem 0.525rem;
    }
  }

  @media screen and (max-width: 960px) {
    max-height: fit-content;

    &__zoom-container {
      padding: 0 3rem;
    }

    &__tabs-list {
      font-size: 0.75rem;
      column-gap: 1rem;
    }

    &__tabs-content-wrapper {
      grid-template-columns: none;
    }
  }

  @media screen and (max-width: 640px) {
    &__zoom-container {
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__zoom-container {
      padding: 0 0.75rem;
    }

    &__tabs-list {
      column-gap: unset;
    }

    &__tabs-list-item {
      padding: 0 0.25rem 0.75rem;
    }
  }
}
