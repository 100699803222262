.dropdown-wrapper {
  position: relative;

  &__options {
    position: absolute;
    bottom: 2.75rem;
    box-shadow: var(--shadow-middle);
    border-radius: 6px;
    padding: 6px 0;
    max-height: 14rem;
    overflow: auto;
    width: 400px;
    max-width: 100%;
    background-color: var(--color-background-primary);
    z-index: 1000;
    scrollbar-color: var(--color-control-inactive-tab-bar) transparent;
    scrollbar-gutter: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-control-inactive-tab-bar);
      border-radius: 0.25rem;
      width: 0.25rem;
    }
  }

  &__option {
    display: flex;
    flex-direction: column;
    row-gap: 0.125rem;
    padding: 0.25rem 0.75rem;
    font-family: 'MTS Compact';
    cursor: pointer;

    & > p {
      margin: 0;
    }

    &:hover {
      background-color: var(--color-background-hover);
    }
  }

  &__info-option {
    margin: 10px 0;
    text-align: center;
    font-size: 17px;
    line-height: 1.5rem;

    &--error {
      color: var(--color-accent-negative);
    }
  }
}
