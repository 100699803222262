.main {
  min-height: calc(100vh - 3.375rem);
  max-height: calc(100vh - 3.375rem);
  background: linear-gradient(
    0.142turn,
    rgb(139, 176, 253) 0%,
    rgb(151, 182, 239) 25%,
    rgb(239, 210, 225) 96%
  );
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
  display: flex;
  padding-top: 3.375rem;
  position: relative;

  @supports not (-moz-appearance: none) {
    align-items: center;
  }

  &__zoom-container {
    padding: 0px 4.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2.25rem;
    width: 55%;
    z-index: 1;
  }

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 2.25rem;
    color: var(--color-text-inverted);
    order: 0;
    z-index: 1;
  }

  &__heading-wrapper {
    font-family: 'MTS Wide';
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    & > * {
      margin: 0;
    }
  }

  &__heading {
    font-weight: 500;
    font-size: 2.875rem;
    line-height: 1;
    margin: 0;
  }

  &__subheading {
    font-size: 1.25rem;
    line-height: 1.5rem;
    width: 80%;
  }

  &__description {
    margin: 0;
    padding-left: 1rem;
    font-family: 'MTS Text';
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  &__buttons {
    display: flex;
    column-gap: 0.625rem;
    row-gap: 0.625rem;
    flex-wrap: wrap;
    z-index: 1;
    order: 2;

    & > button {
      font-size: 0.625rem;
      line-height: 1.25rem;
      letter-spacing: 0.5px;
      font-family: 'MTS Wide';
      font-weight: 700;
      padding: 0.75rem 3.375rem;
      text-transform: uppercase;
      flex: 1;
      min-width: fit-content;
    }
  }

  &__image {
    position: absolute;
    bottom: 0.25rem;
    right: 0;
    z-index: 0;
    width: 87%;
    pointer-events: none;
  }

  @media screen and (max-width: 1280px) {
    &__image {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 960px) {
    padding: 7rem 0 2rem;
    min-height: calc(100vh - 9rem);
    max-height: unset;
    height: auto;
    overflow: hidden;

    &__zoom-container {
      padding: 0px 3rem;
    }

    &__wrapper {
      width: 100%;
      justify-content: space-between;

      & > button {
        width: 100%;
      }
    }

    &__description {
      height: 100%;
      justify-content: space-between;

      & > button {
        width: 100%;
      }
    }

    &__description {
      height: 100%;
      justify-content: space-between;
    }

    &__image {
      transform: none;
      width: 150%;
      position: static;
      margin-right: -3rem;
      order: 1;
      align-self: flex-end;
    }
  }

  @media screen and (max-width: 640px) {
    &__zoom-container {
      padding: 0 2rem;
    }

    &__text {
      row-gap: 1.5rem;
    }

    &__heading-wrapper {
      row-gap: 0.75rem;
    }

    &__heading {
      font-size: 2.5rem;
    }

    &__subheading {
      font-size: 1.125rem;
    }

    &__description {
      font-size: 15px;
    }

    &__image {
      margin-right: -2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__zoom-container {
      padding: 0 0.75rem;
    }

    &__image {
      transform: none;
      width: 150%;
      position: static;
      margin-right: -3rem;
      order: 1;
      align-self: flex-end;
    }
  }

  @media screen and (max-width: 640px) {
    &__zoom-container {
      padding: 0 2rem;
    }

    &__text {
      row-gap: 1.5rem;
    }

    &__heading-wrapper {
      row-gap: 0.75rem;
    }

    &__heading {
      font-size: 2.5rem;
    }

    &__subheading {
      font-size: 1.125rem;
    }

    &__description {
      font-size: 15px;
    }

    &__image {
      margin-right: -2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__zoom-container {
      padding: 0 0.75rem;
    }

    &__heading {
      font-size: 1.75rem;
    }

    &__subheading {
      font-size: 1rem;
    }

    &__description {
      font-size: 0.75rem;
    }

    &__image {
      margin-right: -0.75rem;
    }
  }
}
