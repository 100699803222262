.start-page {
  display: flex;
  flex-direction: column;
  row-gap: 6.25rem;
  margin-bottom: 6.25rem;
}

.section-heading {
  margin: 0;
  color: #393939;
  font-family: 'MTS Wide';
  font-size: 1.625rem;
  line-height: 1.12;
  font-weight: 400;
  text-transform: uppercase;

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
}
