html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'MTS Compact';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @supports (-moz-appearance: none) {
    overflow-x: hidden;
  }
}
