$mts-compact: 'MTS Compact';
$text-negative-color: #d8400c;

@mixin mts-compact-normal {
  font-family: $mts-compact;
  font-style: normal;
}

.personal-data {
  display: flex;
  column-gap: 0.75rem;
  font-size: 17px;
  line-height: 1.5rem;
  padding-top: 0.625rem;

  &__link {
    color: var(--color-accent-active);
    text-decoration: none;
  }

  &__invalid {
    @include mts-compact-normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text-negative-color;
    width: 280px;
  }
}
